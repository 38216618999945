<template>
  <div id="app">
    <AuthenticationComponent v-if="isLoginPage" />
    <template v-else>
      <div class="main-wrapper">
        <header-component />
        <router-view />
        <footer-component />
      </div>
    </template>
    <notifications group="success" />
    <notifications group="error" />
    <notifications group="warning" />
  </div>
</template>

<script>
/* eslint-disable */
import AuthenticationComponent from './components/AuthenticationComponent.vue';
import HeaderComponent from './components/HeaderComponent.vue';
import FooterComponent from './components/FooterComponent.vue';

export default {
  name: 'App',
  data() {
    return {
      isLoginPage: false
    }
  },
  components: {
    AuthenticationComponent,
    HeaderComponent,
    FooterComponent
  },
  mounted() {
    let baseUrlPattern = /^https?:\/\/[a-z\\:0-9.]+/;
    const url = window.location.href.replace(baseUrlPattern, "");
    this.isLoginPage = url === '/';
  },
  watch: {
    '$route' (to, from) {
      this.isLoginPage = this.$route.name === 'AuthenticationComponent';
    }
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100vh;
}
</style>
