<template>
    <footer class="footer px-md-5 py-3 px-3">
        <!-- <div class="container"> -->
            <div class="d-flex align-items-center justify-content-between flex-column flex-md-row">
                <div class="section-left">
                    <span>2024© All Rights Reserved</span>
                    <a href="https://www.enlighten.com.au/about/our-policies">Our Polices and Terms of service</a>
                    <a href="https://www.enlighten.com.au/secure-documents-lighting">Secure zone</a>
                    <a href="https://www.enlink.au/">enLink</a>
                </div>
                <div class="contact-wrapper d-flex align-items-center flex-column flex-sm-row mt-3 mt-md-0">
                    <a href="#">Phone: +61 2 8084 6971</a>
                    <a href="mailto:enlink@enlighten.com.au" class="ml-0 ml-sm-3">enlink@enlighten.com.au</a>&nbsp;
                    <div>
                      <a href="https://www.linkedin.com/company/enlighten-australia" target="_blank"><img src="@/assets/linkedin.png" width="25" style="margin-right: 5px;"/></a>
                      <a href="https://www.youtube.com/channel/UCfj0oP7GDr-YGTlLCmwsfFA" target="_blank"><img src="@/assets/youtube.png" width="25" style="margin-right: 5px;"/></a>
                      <a href="https://www.facebook.com/enlightenaustralia" target="_blank"> <img src="@/assets/facebook.png" width="25" style="margin-right: 5px;"/></a>
                      <a href="https://www.instagram.com/enlightenaustralia/" target="_blank"> <img src="@/assets/instagram.png" width="25" /></a>
                    </div>
                </div>
            </div>
        <!-- </div> -->
    </footer>
</template>

<script>
export default {
    name: 'FooterComponent'
}
</script>

<style lang="scss" scoped>
    footer {
        border-top: 1px solid #CCC;
        * {
            color: #777;
        }
        a {
            transition: color .25s;
            &:hover {
                color: #97c93c;
            }            
        }
        .section-left {
            > * {
                &:not(:first-child) {
                    margin-left: 20px;
                    position: relative;
                    &::before {
                        height: 80%;
                        width: 1px;
                        background-color: #CCC;
                        top: 10%;
                        left: -10px;
                        content: '';
                        position: absolute;
                    }
                }
            }
        }
    }
</style>