<template>
    <div class="container h-100">
        <div class="row w-100">
            <div class="col-12">
                <div class="auth-form" tabindex="-1">
                    <img src="@/assets/img_logo.png" width="150" class="mb-3" />
                    <template v-if="!isBaseLogin">
                        <div class="email-wrapper">
                            <label class="form-label text-left w-100">Email</label>
                            <input class="form-control" ref="email" type="text" placeholder="Email Address" v-model="userEmail" autocomplete="off" @keypress="handleKeyPress"/>
                        </div>
                        <div class="password-wrapper mt-3">
                            <label class="form-label text-left w-100">Password</label>
                            <input class="form-control" ref="password" type="password" placeholder="Password" v-model="userPassword" autocomplete="off" @keypress="handleKeyPress"/>
                        </div>
                        <button type="button" class="btn btn-normal mt-4 btn-icon-button mx-auto" @click="login()">
                            <b-icon icon="arrow-right" class="mr-2"></b-icon>
                            <span>Log in</span>
                        </button>
                    </template>
                    <template v-else>
                        <template v-if="qrcode">
                            <div class="mt-3">
                                <label>Your account for enLink was just updated with Multi-Factor Authentication. Please scan following QR code in an authenticator app to complete the setup process.</label>
                                <img v-bind:src="'data:image/jpeg;base64,'+qrcode" />
                            </div>
                        </template>
                        <div class="password-wrapper mt-3">
                            <label class="form-label text-left w-100">6-Digits Code</label>
                            <input class="form-control" ref="code" type="text" placeholder="Verification Code" v-model="code" autocomplete="off" @keypress="handleKeyPress"/>
                        </div>
                        <button type="button" class="btn btn-normal mt-4 btn-icon-button mx-auto" @click="verify()">
                            <b-icon icon="arrow-right" class="mr-2"></b-icon>
                            <span>Verify</span>
                        </button>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AUTHENTICATION from '../graphql/Authentication.gql';
import MFA from '../graphql/MFA.gql';

export default {
    name: 'AuthenticationComponent',
    data() {
        return {
            userEmail: '',
            userPassword: '',
            isBaseLogin: false,
            code: '',
            loginToken: '',
            qrcode: '',
        }
    },
    mounted() {
        //const self = this;
        //document.addEventListener('keypress', function(e) {
        //    self.onKeyPress(e);
        //});
        if (this.$refs['email']) {
            this.$refs['email'].focus();
        }
    },
    methods: {
        async handleKeyPress(event) {
          if (event.keyCode === 13) {
            if (!this.isBaseLogin) {
                await this.login();
            } else {
                await this.verify();
            }
            event.preventDefault();
          }
        },
        async verify() {
            if (this.code.length !== 6) {
                this.$refs['code'].focus();
                this.$notify({
                    group: "error",
                    type: "error",
                    title: "Error!",
                    text: "Please input the correct verification code.",
                });
                return;
            }

            const self = this;
            await this.$apollo.mutate({
                mutation: MFA,
                variables: {
                    token: self.loginToken,
                    code: self.code
                },
                update: (store, { data }) => {
                    if (data.verifyMFA) {
                        if (data.verifyMFA.accessToken) {
                            localStorage.setItem("user", JSON.stringify(data.verifyMFA));
                            this.$router.push("/sites");
                        } else {
                            this.$notify({
                                group: "error",
                                type: "error",
                                title: "Error!",
                                text: data.verifyMFA.message,
                            });
                        }
                    } else {
                        console.log(data);
                    }
                }
            }).catch((e) => {
                this.$notify({
                    group: "error",
                    type: "error",
                    title: "Error!",
                    text: e.message,
                });
            })
        },
        async login() {
            if (this.userEmail.length < 1) {
                this.$refs['email'].focus();
                return;
            }
            if (this.userPassword.length < 1) {
                this.$refs['password'].focus();
                return;
            }
            
            localStorage.removeItem("user");
            
            await this.$apollo.mutate({
                mutation: AUTHENTICATION,
                variables: {
                    email: this.userEmail,
                    password: this.userPassword,
                },
                update: (store, { data }) => {
                    if (!data.login) {
                        return
                    }

                    if (!data.login.accessToken) {
                        this.$notify({
                            group: "error",
                            type: "error",
                            title: "Error!",
                            text: data.login.message,
                        });
                    }

                    const user = data.login.user;

                    if (user.mfaEnabled) {
                        this.loginToken = data.login.accessToken;
                        this.isBaseLogin = true;
                        this.qrcode = data.login.user.mfaQrcode;
                        return
                    }


                    localStorage.setItem("user", JSON.stringify(data.login));
                    this.$router.push("/sites");

                }
            }).catch((e) => {
                this.$notify({
                    group: "error",
                    type: "error",
                    title: "Error!",
                    text: e.message,
                });
            }) 
            
        }
    }
}
</script>

<style lang="scss" scoped>
    .container {
        min-height: 100vh;
        display: flex;
        align-items: center;
        .auth-form {
            max-width: 360px !important;
            margin: auto;
        }
    }
    img {
      margin-top: 20px;
      max-width: 100%;
      height: auto;
    }
</style>