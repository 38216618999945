import { ApolloLink } from '@apollo/client/core'
import app from "@/main";

export default {
    login(user) {
        localStorage.setItem('user', JSON.stringify(user));
    },
    isLoggedIn() {
        if (!localStorage.getItem('user')) {
            return false;
        } else if (localStorage.getItem('user')) {
            const user = JSON.parse(localStorage.getItem('user'));
            return user.accessToken;
        }
    },
    logout() {
        localStorage.removeItem('user');
    },
    getUserEmail() {
        const user = JSON.parse(localStorage.getItem('user'));
        return user?.user?.email;
    },
    getAuthorizationKey() {
        if (localStorage.getItem('user')) {
            const user = JSON.parse(localStorage.getItem('user'));
            return user.accessToken;
        } else {
            return null;
        }
    },
    /**
     * Handles a graphql query if the isAuthenticated field exists. If it does exist, then it checks
     * to see if the User was authenticateds. If not, it will redirect the client to the logins screen.
     */
    createHandleApolloIsAuth() {

        return new ApolloLink((operation, forward) => {
            return forward(operation).map((data) => {
                const errors = data.errors
                const error = errors && errors[0]

                if (error?.message === 'Unauthorized') {
                    app.$router.push('/');
                }

                return data;
            });
        });
    },
}